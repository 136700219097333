import React from "react"
import Layout from "../components/layout"
import { Container } from 'react-bootstrap/'
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import TopHeader from "../components/top-header"
import { FaArrowLeft } from 'react-icons/fa';
import styled from 'styled-components'

export default ({ data }) => {
  const post = data.markdownRemark;
  const BlogWrapper = styled.div`
  max-width: 1100px;
`;

  return (<Layout>
    <TopHeader />
    <SEO
      title={post.frontmatter.title}
      description={post.frontmatter.desc || data.markdownRemark.excerpt}
      imageURL={
        post.frontmatter.imageURL
      }
      ogType={post.frontmatter.ogType || 'article'}
    />
    <Container className='content-container'>
      <BlogWrapper>
        <div className='guide-box-blog'>
          <div className='content-blog-info'>
            <Link className='blog-arrow ' title='back' aria-label='back' to='/blog'><FaArrowLeft /></Link> <p className='guide-box-desc'> {post.frontmatter.date}</p></div>
          {
            post.frontmatter.withoutImage === true ?
              null
              :
              post.frontmatter.imageURL ?
                <img src={post.frontmatter.imageURL} alt={post.frontmatter.BlogImageAlt || post.frontmatter.title} />
                :
                null
          }
          <h1 className='guide-box-title-blog'>{post.frontmatter.title}</h1>
          <div className='guide-box-desc' dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </BlogWrapper>
    </Container>
  </Layout >
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        desc
        path
        withoutImage
        date(formatString: "LL", locale: "TR")
        BlogImageAlt
        imageURL
        ogType
      }
      excerpt(pruneLength: 160)
      timeToRead
       }
  }
  
`;
